import { WEBSITE_URL } from "./constants";

export function meta(options: {
  title: string;
  description?: string;
  url: string;
  thumbnail?: {
    url?: string;
    width?: number;
    height?: number;
  };
}): JSX.IntrinsicElements["meta"][] {
  return [
    { property: "og:type", content: "website" },
    { property: "og:url", content: options.url },
    { property: "og:title", content: options.title },
    ...(options.description
      ? [
          { property: "og:description", content: options.description },
          { name: "description", content: options.description },
        ]
      : []),
    ...(options.thumbnail?.url &&
    options.thumbnail.width &&
    options.thumbnail.url
      ? [
          { property: "og:image", content: options.thumbnail.url },
          {
            property: "og:image:width",
            content: String(options.thumbnail.width),
          },
          {
            property: "og:image:height",
            content: String(options.thumbnail.height),
          },
        ]
      : []),
  ];
}
