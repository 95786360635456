export const WEBSITE_URL = "https://yebon.kim";

export const WEBSITE_TITLE = "Droid Morning by Bonny";

export const WEBSITE_DESCRIPTION = "Bonny의 테크 블로그";

export const HERO_PARAGRAPH = `어제의\n한계 넘기`;

export const PROFILE_NAME = "Bonny";

export const PROFILE_CAPTION = "안드로이드 엔지니어 / 딜라이트룸";

export const PROFILE_CV_URL = "https://read.cv/yebonkim";

export const PROFILE_GITHUB_URL = "https://github.com/yebonkim";

export const PROFILE_FACEBOOK_URL = "https://www.facebook.com/yebonkim.bony/";

export const TRANSITION_DURATION = "400ms";

export const UTTERENCES_REPO = "yebonkim/yebon.kim.comments";

export const UTTERENCES_TYPE = "pathname";
